<template>
  <v-card flat class="my-5">
    <v-row class="mx-5 mb-2">
      <v-col cols="10"></v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded @click="create" color="primary">
          <span class="font-weight-bold hidden-xs-only">Создать</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <hour v-for="hour in workhours" :key="hour.id" :hour="hour" @change="loadWorkHours" />
  </v-card>
</template>

<script>
import axios from "../../../services/axios";
import hour from "./hour";

export default {
  components: { hour },
  props: {
    store_id: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      t: true,
      weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      workhours: []
    }
  },

  created() {
    this.loadWorkHours();
  },

  methods: {
    loadWorkHours() {
      axios({
        url: "/store/hours/" + this.store_id,
        method: "GET"
      }).then(resp => {
        this.workhours = resp.data;
        this.workhours.forEach(i => {
          i.weekday = this.weekdays[i.weekday];
        })
      });
    },

    create() {
      let item = {
        weekday: 0,
        start_at: "00:00",
        end_at: "00:00",
        save: true
      };
      this.workhours.push(item);
    },
  }
}
</script>
