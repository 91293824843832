<template>
  <v-card flat class="my-4">
    <v-row>
      <v-col cols="12" class="row no-gutters justify-center d-flex align-center px-8">
        <v-text-field
            rounded
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            ref="search"
            v-model="search"
            full-width
            hide-details
            label="Поиск по блюдам"
            single-line
            @change="fetchItems"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="row no-gutters d-flex align-center py-0">
        <v-btn v-show="$vuetify.breakpoint.xsOnly" small class="px-0" @click="mob = !mob">
          <v-icon>{{ mob ? 'mdi-arrow-right' : 'mdi-arrow-left' }}</v-icon>
        </v-btn>
        <v-card :class="{'test-hide': mob}" color="grey lighten-4" class="px-3">
          <v-row no-gutters class="justify-start">
            <v-col v-for="(selection, i) in selected" :key="selection.id" class="shrink pa-2">
              <v-chip small color="primary">
                <span>{{ selection.priority }} - {{ selection.name }}</span>
                <v-icon right small @click="selected.splice(i, 1)">mdi-close-circle</v-icon>
              </v-chip>
            </v-col>
            <v-col v-for="c in filteredCategories" :key="c.id" class="shrink pa-2">
              <v-chip small color="grey lighten-1">
                <span>{{ c.priority }} - {{ c.name }}</span>
                <v-icon right small @click="selected.push(c)">mdi-plus-circle</v-icon>
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="row no-gutters justify-center d-flex align-center">
        <v-row no-gutters class="justify-space-around">
          <newCard @save="itemAdded"/>
          <div v-for="item in items" :key="item.id">
            <card :row="item" @delete="fetchItems" />
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import card from "./item/index";
import newCard from "./item/new";
import {uuid} from "uuidv4";

export default {
  components: {card, newCard},
  data() {
    return {
      mob: true,
      show: false,
      store_id: uuid.Nil,
      categories: [],
      select: [],
      search_item: "",
      search: "",
      selected: [],
      items: [],
      oldItem: "",
    };
  },

  created() {
    this.store_id = this.$route.params.id;
    this.fetchCategories();
    this.fetchItems();
  },

  computed: {
    allSelected() {
      return this.selected.length === this.categories.length;
    },
    filteredCategories() {
      return this.categories.filter((el) => !this.selected.includes(el));
    },
  },

  watch: {
    selected() {
      this.search = "";
      this.fetchItems();
    },
  },

  methods: {
    fetchCategories() {
      axios
          .send({
            url: "/categories/store/" + this.store_id,
            method: "GET",
          })
          .then((resp) => {
            this.categories = resp.data;
          });
    },
    fetchItems() {
      let listSelectedCategories = [];
      this.selected.forEach((element) =>
          listSelectedCategories.push(element.id)
      );

      axios
          .send({
            url: "/store/menu",
            data: {store_id: this.store_id, tags: listSelectedCategories, search: this.search},
            method: "POST",
          })
          .then((resp) => {
            this.items = resp.data;
          });
    },
    itemAdded(v) {
      this.items.push(v);
    }
  },
};
</script>

<style scoped>
@media (max-width: 600px) {
  .test-hide {
    display: none;
  }
}
</style>
