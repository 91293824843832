<template>
  <v-row class="px-2">
    <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
      <v-select
          hide-details
          rounded
          outlined
          dense
          v-model="weekday"
          :items="weekdays"
          label="День недели"
          persistent-hint
          return-object
          single-line
          @change="save = true"/>
    </v-col>
    <v-col cols="5" class="col col-lg-3 col-md-3 col-sm-3">
      <v-text-field v-model="start_at"
                    hide-details
                    dense
                    outlined
                    rounded
                    label="Начало"
                    @input="save = true"/>

    </v-col>
    <v-col cols="5" class="col col-lg-3 col-md-3 col-sm-3">
      <v-text-field v-model="end_at"
                    hide-details
                    dense
                    outlined
                    rounded
                    label="Конец"
                    @input="save = true"/>
    </v-col>
    <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
      <v-btn v-if="save" icon color="primary" @click="addWorkHour()">
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
      <v-btn v-else icon color="red" @click="removeWorkHour()">
        <v-icon>mdi-minus-circle</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "../../../services/axios";
import {uuid} from "uuidv4";

export default {
  props: {
    hour: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      t: true,
      weekdays: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      id: uuid.Nil,
      weekday: "Воскресенье",
      start_at: "00:00",
      end_at: "00:00",
      save: true,
    }
  },
  created() {
    if (this.hour.id) {
      this.id = this.hour.id;
      this.weekday = this.hour.weekday;
      this.start_at = this.hour.start_at;
      this.end_at = this.hour.end_at;
      this.save = false;
    }
  },
  methods: {
    addWorkHour() {
      let storeID = this.$route.params.id
      let w = this.weekdays.findIndex(i => {
        return i === this.weekday
      });
      axios({
        url: "/store/hours/" + storeID,
        method: "POST",
        data: {
          weekday: w,
          start_at: this.start_at,
          end_at: this.end_at,
          id: this.id,
        }
      }).then(() => {
        this.$emit("change")
      });
    },

    removeWorkHour() {
      axios({
        url: "/store/hours/" + this.id,
        method: "DELETE"
      }).then(() => {
        this.$emit("change")
      });
    },
  }
}
</script>
