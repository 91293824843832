<template>
  <v-card outlined class="cards py-1">
    <v-row no-gutters class="mx-2 pb-1">
      <div class="d-flex align-center">
        <span class="text-body-2 font-italic">Создать новый тэг</span>
      </div>
      <v-spacer></v-spacer>
      <div class="">
        <v-btn @click="dialog = true" x-small fab>
          <v-icon>mdi-plus-thick</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters class="mx-2 pt-1" v-for="t in list" :key="t.id">
      <div class="col-6 row no-gutters justify-start d-flex align-center">
        <span class="font-weight-bold">{{ t.tag }}</span>
      </div>
      <div class="col-6 row no-gutters justify-end d-flex align-center">
        <v-btn @click="edit(t)" class="mr-4" x-small color="transparent" fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="del(t)" x-small color="transparent" fab>
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>{{ isNew() ? "Новый тэг" : "Редактировать тэг" }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.tag" label="Тэг"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3">
          <v-btn rounded outlined elevation="3" color="error" @click="close">Отменить</v-btn>
          <v-btn rounded outlined elevation="3" color="primary" @click="save">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      store_id: uuid.Nil,
      list: [],
      dialog: false,
      item: {
        id: uuid.Nil,
        store_id: uuid.Nil,
        tag: "",
      },
      defaultItem: {
        id: uuid.Nil,
        store_id: uuid.Nil,
        tag: "",
      },
    };
  },

  created() {
    this.store_id = this.$route.params.id;
    this.fetch();
  },

  methods: {
    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },
    fetch() {
      axios
          .send({
            url: "/store/search/" + this.store_id,
            method: "GET",
          })
          .then((resp) => {
            if (resp.data) {
              this.list = resp.data;
            }
          });
    },
    save() {
      this.item.store_id = this.store_id;
      axios.send({
        url: "/store/search/" + this.store_id,
        method: "POST",
        data: this.item
      }).then(() => {
        this.close();
        this.fetch();
      });
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
      })
    },
    edit(item) {
      this.item = item
      this.dialog = true
    },
    del(item) {
      this.$confirm(
          "Удалить тэг ?",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/store/search/" + this.store_id,
          method: "DELETE",
          data: item
        }).then(() => {
          this.fetch();
        });
      });
    }
  }
};
</script>

<style scoped>
.cards {
  border-radius: 15px;
}
</style>
