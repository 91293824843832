<template>
  <v-card outlined class="cards">
    <v-row no-gutters class="mr-4 ml-2" v-for="z in zones" :key="z.id">
      <div class="row no-gutters justify-start d-flex align-center">
        <v-avatar>
          <v-icon>mdi-map-marker</v-icon>
        </v-avatar>
        <span class="font-weight-bold text-uppercase">{{ z.name }}</span>
      </div>
      <div class="row no-gutters justify-end d-flex align-center">
        <v-checkbox class="py-0 my-0" color="primary" hide-details :value="t" @change="remove(z.id)"/>
      </div>
    </v-row>
    <v-row no-gutters class="mr-4 ml-2" v-for="z in filteredZones" :key="z.id">
      <div class="row no-gutters justify-start d-flex align-center">
        <v-avatar>
          <v-icon>mdi-map-marker</v-icon>
        </v-avatar>
        <span class="font-weight-bold text-uppercase">{{ z.name }}</span>
      </div>
      <div class="row no-gutters justify-end d-flex align-center">
        <v-checkbox class="py-0 my-0" color="primary" hide-details :value="f" @change="add(z.id)"/>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import axios from "../../../services/axios";

export default {
  props: {
    store_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      allZones: [],
      zones: [],
      t: true,
      f: false
    }
  },

  created() {
    this.fetchZones();
    this.fetchStoreZones();
  },

  computed: {
    filteredZones() {
      return this.allZones.filter(z => {
        let contains = false;
        this.zones.forEach(zz => {
          if (zz.id === z.id) {
            contains = true;
          }
        })
        return !contains;
      })
    }
  },

  methods: {
    fetchZones() {
      axios({
        url: "/zones",
        method: "GET"
      }).then(resp => {
        this.allZones = [];
        this.allZones = resp.data;
      });
    },
    fetchStoreZones() {
      axios({
        url: "/store/zones/" + this.store_id,
        method: "GET"
      }).then(resp => {
        this.zones = [];
        this.zones = resp.data;
      });
    },
    add(zone_id) {
      axios({
        url: "/store/zones/" + this.store_id + "/" + zone_id,
        method: "POST"
      }).then(() => {
        this.fetchStoreZones();
      });
    },
    remove(zone_id) {
      axios({
        url: "/store/zones/" + this.store_id + "/" + zone_id,
        method: "DELETE"
      }).then(() => {
        this.fetchStoreZones();
      });
    }
  }
}
</script>

<style scoped>
.cards {
  border-radius: 15px;
}
</style>
