<template>
  <v-card class="pb-3">
    <v-toolbar class="header row no-gutters justify-center" color="primary" dense>
      <v-toolbar-title v-if="item.id" class="mt-1">Изменить</v-toolbar-title>
      <v-toolbar-title v-else class="mt-1">Добавить блюдо</v-toolbar-title>
    </v-toolbar>
    <v-row class="justify-space-between mx-4 mt-5" no-gutters>
      <v-col cols="12">
        <v-text-field v-model="item.name" clearable dense label="Название блюда" outlined required rounded/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3" :class="$vuetify.breakpoint.xs ? 'pr-1' : ''">
        <v-text-field v-model="item.price" dense label="Цена (сом)" outlined required rounded/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3" :class="$vuetify.breakpoint.xs ? 'pl-1' : ''">
        <v-text-field v-model="item.mass" dense label="Вес (гр.)" outlined rounded/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
        <v-text-field v-model="item.cook_time" dense label="Время приг. (мин.)" outlined rounded/>
      </v-col>
      <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 row justify-center mt-2">
        <v-checkbox color="primary" v-model="item.discount_disabled" class="ma-0 pa-0" hide-details />
        <span>НЕ уч. в скидках</span>
      </v-col>
      <v-col cols="4" class="col col-lg-2 col-md-2 col-sm-2">
          <v-text-field v-model="item.start_at"
                        class="mb-4 mt-1"
                        hide-details
                        dense
                        outlined
                        rounded
                        label="Начало"/>
          <v-text-field v-model="item.finish_at"
                        dense
                        outlined
                        rounded
                        label="Конец"/>
        </v-col>
      <v-col cols="8" class="col col-lg-4 col-md-4 col-sm-4">
        <v-chip-group column class="ml-3" multiple active-class="primary" v-model="item.weekdays">
          <v-chip ripple v-for="day in weekdays" :key="day" small color="grey lighten-1">{{ day }}</v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6">
        <v-textarea v-model="item.description"
                    :placeholder="item.description"
                    dense
                    label="Описание"
                    outlined
                    rounded
                    height="100px"/>
      </v-col>
      <v-col class="mb-4" cols="12">
        <v-card class="row no-gutters myCard py-1" color="grey lighten-4">
          <v-col class="mx-3 shrink">
            <v-tooltip top>
              <template v-slot:activator="{ attrs, on }">
                <v-btn @click="editCategories = !editCategories" icon v-bind="attrs" v-on="on" small elevation="5">
                  <v-icon v-show="editCategories">mdi-chevron-down</v-icon>
                  <v-icon v-show="!editCategories">mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              Редактировать категории
            </v-tooltip>
          </v-col>
          <v-col v-for="(selection, i) in item.categories" :key="selection.id" class="my-1 shrink mx-2">
            <v-chip color="primary" small>
              <span>{{ selection.priority }} - {{ selection.name }}</span>
              <v-icon right small @click="item.categories.splice(i, 1)">mdi-close-circle</v-icon>
            </v-chip>
          </v-col>
          <v-col v-for="c in filteredCategories" :key="c.id" class="my-1 shrink mx-2">
            <v-chip v-if="!editCategories" color="grey lighten-1" small>
              <span>{{ c.priority }} - {{ c.name }}</span>
              <v-icon right small @click="item.categories.push(c)">mdi-plus-circle</v-icon>
            </v-chip>
          </v-col>
        </v-card>
      </v-col>
      <v-col class="mb-4" cols="12">
        <v-card class="row no-gutters myCard py-1" color="grey lighten-4">
          <v-col class="mx-3 shrink">
            <v-tooltip top>
              <template v-slot:activator="{ attrs, on }">
                <v-btn @click="editChildren = !editChildren" icon v-bind="attrs" v-on="on" small elevation="5">
                  <v-icon v-show="editChildren">mdi-chevron-down</v-icon>
                  <v-icon v-show="!editChildren">mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              Редактировать прикрепленные блюда
            </v-tooltip>
          </v-col>
          <v-col v-for="(child, i) in item.children" :key="child.id" class="my-1 shrink mx-2">
            <v-chip color="cyan" small>
              <v-icon color="white" small left @click="child.count ++ ">mdi-plus-circle-outline</v-icon>
              <span>{{ child.count }} - {{ child.name }}</span>
              <v-icon right small @click="item.children.splice(i, 1)">mdi-close-circle</v-icon>
            </v-chip>
          </v-col>
          <v-col v-for="c in filteredChildren" :key="c.id" class="my-1 shrink mx-2">
            <v-chip v-if="!editChildren" color="grey lighten-1" small>
              <span>{{ c.name }}</span>
              <v-icon right small @click="item.children.push(c)">mdi-plus-circle</v-icon>
            </v-chip>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-text-field v-model="item.notification"
                      :placeholder="item.notification"
                      dense
                      label="Уведомления"
                      outlined
                      rounded/>
      </v-col>
      <v-row no-gutters class="justify-space-around">
        <v-btn ripple elevation="3" rounded outlined class="col-5" color="error" @click="close">
          <span>Отменить</span>
        </v-btn>
        <v-btn ripple elevation="3" rounded outlined class="col-5" color="primary" @click="save">
          <span>Сохранить</span>
        </v-btn>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      editCategories: true,
      editChildren: true,
      item: {},
      categories: [],
      children: [],
      t: true,
      weekdays: [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
      ],
    }
  },
  props: {
    row: Object
  },
  created() {
    this.item = Object.assign({}, this.row);
    this.fetchCategories();
    this.fetchChildren();
  },
  computed: {
    filteredCategories() {
      return this.categories.filter((el) => !this.item.categories.some(e => el.id === e.id));
    },
    filteredChildren() {
      return this.children.filter((el) => !this.item.children.some(e => el.id === e.id));
    }
  },
  methods: {
    fetchCategories() {
      axios
          .send({
            url: "/categories/store/" + this.$route.params.id,
            method: "GET",
          })
          .then((resp) => {
            this.categories = resp.data;
          });
    },
    fetchChildren() {
      axios
          .send({
            url: "/items/hidden/" + this.$route.params.id,
            method: "GET",
          })
          .then((resp) => {
            this.children = resp.data;
          });
    },

    save() {
      this.item.cook_time = parseInt(this.item.cook_time);
      this.item.mass = parseInt(this.item.mass);
      this.item.price = parseFloat(this.item.price);

      axios({
        url: "/items",
        method: "PUT",
        data: this.item
      }).then((resp) => {
        let id = this.item.id;
        this.item = resp.data;
        if (!id) {
          this.item = Object.assign({}, {
            id: uuid.Nil,
            name: '',
            cook_time: 0,
            mass: 0,
            price: 0,
            discount_disabled: false,
            description: '',
            notification: '',
            categories: [],
            children: [],
            store_id: this.$route.params.id,
          });
        }
        this.$emit("save", resp.data)
      });
    },
    close() {
      this.$emit("closed")
    },
  }
}
</script>

<style scoped>

.header {
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
}

.myCard {
  border-radius: 25px;
}

</style>
