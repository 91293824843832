<template>
  <v-card class="my-4">
    <v-toolbar dense color="green darken-2 my-1" dark>
      <v-toolbar-title class="row no-gutters">
        <v-col cols="4" class="row no-gutters justify-start d-flex align-center">
          <div>
            <v-file-input hide-input prepend-icon="mdi-microsoft-excel" @change="upload"/>
          </div>
          <span class="text-body-1 hidden-xs-only">Загрузить</span>
        </v-col>
        <v-col cols="4" class="row no-gutters justify-center d-flex align-center">
          <span class="font-weight-bold">{{ item.name }}</span>
        </v-col>
        <v-col cols="4" class="row no-gutters justify-end d-flex align-center">
          <v-icon @click="download">mdi-microsoft-excel</v-icon>
          <span class="ml-2 text-body-1 hidden-xs-only">Выгрузить</span>
        </v-col>
      </v-toolbar-title>
      <template v-slot:extension>
        <v-tabs v-model="tab" background-color="transparent" slider-color="indigo" grow>
          <v-tab active-class="green darken-1">
            <span v-if="item.id" class="font-weight-bold">Редактировать</span>
            <span v-else class="font-weight-bold">Новое заведение</span>
          </v-tab>
          <v-tab active-class="green darken-1" v-if="!isNew() && role === 'ADMIN'">
            <span class="font-weight-bold">Рабочие часы</span>
          </v-tab>
          <v-tab active-class="green darken-1" v-if="!isNew() && role === 'ADMIN'">
            <span class="font-weight-bold">Условия доставки</span>
          </v-tab>
          <v-tab active-class="green darken-1" v-if="!isNew()">
            <span class="font-weight-bold">Категории</span>
          </v-tab>
          <v-tab active-class="green darken-1" v-if="!isNew()">
            <span class="font-weight-bold">Меню</span>
          </v-tab>
          <v-tab active-class="green darken-1" v-if="!isNew() && role === 'ADMIN'">
            <span class="font-weight-bold">Старое меню</span>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card flat>
          <v-row no-gutters class="px-2">
            <v-col cols="12" v-if="!isNew()" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters mt-2">
              <div class="row no-gutters col-12 mb-4">
                <v-img v-if="item.logo" :src="item.logo" height="100px" class="picture">
                  <div class="row justify-end no-gutters ma-7">
                    <v-btn small fab ripple elevation="5" @click="$refs.logo.click()">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </div>
                </v-img>
                <v-img v-else :src="'/12.jpg'" height="100px" class="picture">
                  <div class="row justify-end no-gutters ma-7">
                    <v-btn small fab ripple elevation="5" @click="$refs.logo.click()">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </div>
                </v-img>
              </div>
              <div class="row no-gutters col-12">
                <v-img v-if="item.image" :src="item.image" height="400px" class="picture">
                  <div class="row justify-space-between no-gutters ma-4 d-flex align-center">
                    <v-avatar v-if="item.logo" size="72px">
                      <v-img :src="item.logo"/>
                    </v-avatar>
                    <v-avatar v-else size="72px">
                      <v-img src="12.jpg"/>
                    </v-avatar>
                    <v-btn small class="ma-3" fab ripple @click="$refs.image.click()">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </div>
                </v-img>
                <v-img v-else src="/12.jpg" height="400px" class="picture">
                  <div class="row justify-space-between no-gutters ma-4 d-flex align-center">
                    <v-avatar size="72px" v-if="item.logo">
                      <v-img :src="item.logo"/>
                    </v-avatar>
                    <v-avatar v-else size="72px">
                      <v-img src="/12.jpg"/>
                    </v-avatar>
                    <v-btn small class="ma-3" fab ripple @click="$refs.image.click()">
                      <v-icon>mdi-camera</v-icon>
                    </v-btn>
                  </div>
                </v-img>
              </div>
              <div v-show="false">
                <input type="file" id="logo" ref="logo" accept="image/*"
                       @change="handleLogoUpload(item.id)"/>
              </div>
              <div v-show="false">
                <input type="file" id="image" ref="image" accept="image/*"
                       @change="handleImageUpload(item.id)"/>
              </div>
            </v-col>
            <v-col cols="12" class="col col-lg-9 col-md-9 col-sm-9 row no-gutters mt-2 pl-1">
              <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters">
                <span v-if="item.id" class="row no-gutters justify-center text-caption font-weight-bold">
                  Редактировать заведение
                </span>
                <span v-else class="row no-gutters justify-center text-caption font-weight-bold">
                  Создать новое заведение
                </span>
                <v-card outlined class="picture pa-3">
                  <v-text-field dense rounded outlined hide-details v-model="item.name"
                                label="Название"/>
                  <v-text-field class="my-5" dense rounded outlined hide-details v-model="item.commission"
                                label="Комиссия"/>
                  <v-text-field class="my-5" dense rounded outlined hide-details v-model="item.phone"
                                label="Телефон"/>
                  <v-text-field dense rounded outlined hide-details v-model="item.address" label="Адрес"/>
                  <v-row class="mt-2">
                    <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 d-flex align-center">
                      <v-text-field dense rounded outlined hide-details v-model="item.lat" label="Долгота"/>
                    </v-col>
                    <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 d-flex align-center">
                      <v-text-field dense rounded outlined hide-details v-model="item.lng" label="Широта"/>
                    </v-col>
                    <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3 d-flex align-center">
                      <v-text-field dense rounded outlined hide-details v-model="item.radius" label="Радиус км"/>
                    </v-col>
                    <v-col cols="6" class="col col-lg-3 col-md-3 col-sm-3">
                      <v-checkbox class="ma-0 pa-1" hide-details v-model="item.hidden" label="Скрыт." color="primary"/>
                      <v-checkbox class="ma-0 pa-1" hide-details v-model="item.popular" label="Попул." color="primary"/>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="row no-gutters justify-center text-caption font-weight-bold mt-2">Редактировать категории
                  заведения
                </div>
                <v-card outlined class="picture">
                  <v-row no-gutters class="ml-2 my-2 d-flex align-center">
                    <v-btn @click="editItemTags = !editItemTags" icon small elevation="5">
                      <v-icon v-show="!editItemTags">mdi-chevron-down</v-icon>
                      <v-icon v-show="editItemTags">mdi-chevron-up</v-icon>
                    </v-btn>
                    <div class="mt-1 mx-2" v-for="(selection, i) in item.tags" :key="selection.id">
                      <v-chip color="primary" small>
                        <span>{{ selection.name }}</span>
                        <v-icon right small @click="item.tags.splice(i, 1)">mdi-close-circle</v-icon>
                      </v-chip>
                    </div>
                    <div class="mt-1 mx-2" v-for="t in filteredTags" :key="t.id">
                      <v-chip v-if="editItemTags" small color="grey lighten-1">
                        <span>{{ t.name }}</span>
                        <v-icon @click="item.tags.push(t)" right small>mdi-plus-circle</v-icon>
                      </v-chip>
                    </div>
                  </v-row>
                </v-card>
                <v-row class="row justify-space-around my-5">
                  <v-btn ripple elevation="5" rounded outlined color="error" @click="close">
                    <span class="font-weight-bold">Отменить</span>
                  </v-btn>
                  <v-btn ripple elevation="5" rounded outlined color="primary" @click="save">
                    <span class="font-weight-bold">Сохранить</span>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" class="pl-1 col col-lg-6 col-md-6 col-sm-6" v-if="item.id">
                <div>
                  <span class="row no-gutters justify-center text-caption font-weight-bold">Зоны</span>
                  <zones :store_id="storeID"/>
                </div>
                <div class="mt-4">
                  <div>
                    <span class="row no-gutters justify-center text-caption font-weight-bold">Пользователи</span>
                    <users/>
                  </div>
                </div>
                <div class="mt-4" v-if="role === 'ADMIN'">
                  <div>
                    <span class="row no-gutters justify-center text-caption font-weight-bold">Тэги</span>
                    <tags/>
                  </div>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="role === 'ADMIN'">
        <hours :store_id="storeID"/>
      </v-tab-item>
      <v-tab-item v-if="role === 'ADMIN'">
        <rules :store_id="storeID"/>
      </v-tab-item>
      <v-tab-item>
        <categories/>
      </v-tab-item>
      <v-tab-item>
        <items/>
      </v-tab-item>
      <v-tab-item v-if="role === 'ADMIN'">
        <oldmenu/>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import rules from "./rules/index.vue";
import items from "./menu/index.vue";
import hours from "./workhours/index.vue";
import zones from "./zones/index.vue";
import categories from "./categories/index.vue";
import users from "./users/index.vue";
import tags from "./search_tags/index.vue";
import axios from "@/services/axios";
import oldmenu from "../items/index.vue";
import {uuid} from "uuidv4";
import Vue from "vue";

export default {
  components: {rules, hours, items, categories, users, tags, zones, oldmenu},
  data() {
    return {
      tab: null,
      storeID: uuid.Nil,
      item: {
        id: uuid.Nil,
        name: "",
        image: "",
        logo: "",
        lat: 0.0,
        lng: 0.0,
        address: "",
        radius: 0.0,
        commission: 0
      },
      tags: [],
      editItemTags: false
    }
  },

  created() {
    if (this.$route.params.id) {
      this.storeID = this.$route.params.id;
      this.fetch();
      this.fetchTags();
    }
  },

  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
    filteredTags() {
      if (this.item && this.item.tags) {
        return this.tags.filter((el) => !this.item.tags.some(e => el.id === e.id));
      } else
        return []
    },
  },

  methods: {
    fetch() {
      axios.send({
        url: "/store/" + this.storeID,
        method: "GET"
      }).then(resp => {
        this.item = resp.data;
      });
    },
    fetchTags() {
      axios.send({
        url: "/tags/",
        method: "GET"
      }).then(resp => {
        this.tags = resp.data;
      });
    },

    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },

    handleImageUpload() {
      let file = this.$refs.image.files[0];
      let formData = new FormData();
      formData.append("image", file);

      axios.send({
        url: "/store/" + this.storeID + "/image",
        method: "POST",
        data: formData,
      }).then(resp => {
        this.item.image = resp.data;
      });
    },

    handleLogoUpload() {
      let file = this.$refs.logo.files[0];
      let formData = new FormData();
      formData.append("image", file);

      axios.send({
        url: "/store/" + this.storeID + "/logo",
        method: "POST",
        data: formData,
      }).then(resp => {
        this.item.logo = resp.data;
      });
    },

    save() {
      this.item.radius = parseFloat(this.item.radius);
      this.item.lat = parseFloat(this.item.lat);
      this.item.lng = parseFloat(this.item.lng);
      this.item.commission = parseInt(this.item.commission)
      axios.send({
        url: "/store",
        method: "POST",
        data: this.item
      }).then(() => {
        this.close();
      });
    },

    close() {
      this.item.id = uuid.Nil;
      this.item.name = "";
      this.item.image = "";
      this.item.logo = "";
      this.item.address = "";
      this.item.phone = "";
      this.item.lat = 0.0;
      this.item.lng = 0.0;
      this.item.radius = 0;
      this.item.tags = [];

      this.$router.push({name: 'stores'})
    },
    download() {
      axios({
        url: "/items/menu/add/" + this.storeID,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    upload(file) {
      let storeID = this.$route.params.id;
      let formData = new FormData();
      formData.append("menu", file);

      if (file) {
        axios.send({
          url: "/items/menu/add/" + storeID,
          method: "POST",
          data: formData,
          headers: {"Content-Type": "multipart/form-data"}
        }).then(() => {
          file = undefined
          Vue.prototype.$alert(
              "ОК",
              "Блюда успешно загружены",
              "success"
          );
        });
      }
    },
  }
}
</script>

<style scoped>
.picture {
  border-radius: 15px;
}
</style>
