<template>
  <v-card class="mx-2 my-4" elevation="24" flat max-width="250">
    <v-card class="row justify-center no-gutters d-flex align-center" color="rgba(192, 192, 192, 0.6)" height="50">
      <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" block color="transparent" x-large @click="dialog = true">
            <v-icon color="black" x-large>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <edit-card :row="this.item" @closed="dialog = false" @save="save"/>
      </v-dialog>
    </v-card>
    <v-card flat>
      <v-img :src="'/12.jpg'" dark height="200"/>
      <v-divider></v-divider>
      <v-card-title>
        <div class="row justify-center font-weight-bold text-h6">Добавить блюдо</div>
      </v-card-title>
    </v-card>
  </v-card>
</template>
<script>

import editCard from './edit';
import {uuid} from "uuidv4";

export default {
  components: { editCard },
  data() {
    return {
      dialog: false,
      item: {},
      defaultItem: {
        id: uuid.Nil,
        name: '',
        cook_time: 0,
        mass: 0,
        price: 0,
        discount_disabled: false,
        description: '',
        notification: '',
        categories: [],
        children: [],
        weekdays: [0,1,2,3,4,5,6],
        store_id: this.$route.params.id,
      },
    }
  },
  computed: {},
  created() {
    this.item = Object.assign({}, this.defaultItem);
  },
  methods: {
    save(v) {
      this.dialog = false;
      this.item = Object.assign({}, this.defaultItem);
      this.$emit("save", v);
    },
  }
}
</script>

<style scoped>

</style>
