<template>
  <v-card flat class="my-5">
    <v-row class="mx-2 mb-2">
      <v-col cols="10" class="row no-gutters justify-center d-flex align-center">
        <v-text-field rounded
                      outlined
                      dense
                      prepend-inner-icon="mdi-magnify"
                      v-model="search"
                      label="Поиск"
                      hide-details
                      single-line/>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded @click="dialog = true" dark color="primary">
          <span class="font-weight-bold hidden-xs-only">Создать</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="hidden-xs-only">
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="caption ml-4">Изображение</span>
      </v-col>
      <v-col cols="4" class="row no-gutters justify-start">
        <span class="caption ml-4">Категория</span>
      </v-col>
      <v-col cols="1" class="row no-gutters justify-start">
        <span class="caption">Порядок</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-start">
        <span class="caption ml-2">Название</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-start">
        <span class="caption ml-4">Описание</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="caption">Редактировать</span>
      </v-col>
    </v-row>
    <v-row no-gutters :class="$vuetify.breakpoint.xs ? 'px-2' : ''" class="my-1 grey lighten-4 justify-center d-flex align-center" v-for="(cat, index) in filteredList" :key="cat.id">
      <v-col cols="4" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Изображение:</span>
      </v-col>
      <v-col cols="8" :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center">
        <v-avatar size="40px" class="my-1">
          <img v-if="cat.image" :src="cat.image" class="mt-1" alt="" @click="$refs.image[index].click()" />
          <img v-else src="/12.jpg" class="mt-1" alt="" @click="$refs.image[index].click()" />
        </v-avatar>
        <div v-show="false">
          <input type="file" id="image" ref="image" accept="image/*" @change="handleFileUpload(index, cat)"/>
        </div>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span class="caption">Категория:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start">
        <span v-if="cat.name" class="text-body-2">{{ cat.name }}</span>
        <span v-else class="font-italic text-body-2">Нет названия</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Порядок:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2">{{ cat.priority }}</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Название:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-start justify-md-start justify-sm-start">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2" v-if="cat.short_name">{{ cat.short_name }}</span>
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" v-else class="text-body-2">-</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Описание:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 justify-lg-start justify-md-start justify-sm-start row no-gutters justify-end">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="text-body-2" v-if="cat.description">{{ cat.description }}</span>
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" v-else class="text-body-2">-</span>
      </v-col>
      <v-col cols="4" class="row no-gutters hidden-sm-and-up">
        <span :class="$vuetify.breakpoint.xs ? 'pt-1' : ''" class="caption">Редактировать:</span>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-end justify-lg-space-around justify-md-space-around justify-sm-space-around py-1">
        <v-btn :class="$vuetify.breakpoint.xs ? 'mr-3' : ''" :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.mdAndUp" fab>
          <v-icon @click="edit(cat)" color="primary">mdi-pencil</v-icon>
        </v-btn>
        <v-btn :x-small="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.mdAndUp" fab>
          <v-icon color="error" @click="del(cat)">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 500">
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>{{ isNew() ? "Новая категория" : "Редактировать категорию" }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.name" label="Категория"/>
          </v-col>
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.short_name" label="Ключ"/>
          </v-col>
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.description" label="Описание"/>
          </v-col>
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.priority" label="Порядок"/>
          </v-col>
        </v-row>
        <v-row class="justify-space-around pb-3">
          <v-btn rounded outlined elevation="3" color="error" @click="close">Отменить</v-btn>
          <v-btn rounded outlined elevation="3" color="primary" @click="save">Сохранить</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "@/services/axios";
import {uuid} from "uuidv4";

export default {
  data() {
    return {
      search: "",
      store_id: uuid.Nil,
      list: [],
      dialog: false,
      item: {
        id: uuid.Nil,
        name: "",
        short_name: "",
        description: "",
        image: "",
        priority: 0
      },
      defaultItem: {
        id: uuid.Nil,
        name: "",
        short_name: "",
        description: "",
        image: "",
        priority: 0
      }
    }
  },

  created() {
    this.store_id = this.$route.params.id;
    this.fetchCategories();
  },

  computed: {
    filteredList() {
      if (!this.search) {
        return this.list;
      }
      return this.list.filter(row => row.name.toLowerCase().includes(this.search));
    }
  },

  methods: {
    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },
    fetchCategories() {
      axios.send({
        url: "/categories/store/" + this.store_id,
        method: "GET",
      }).then((resp) => {
        this.list = resp.data;
      });
    },
    handleFileUpload(index, cat) {
      let file = this.$refs.image[index].files[0];
      let formData = new FormData();
      formData.append("image", file);

      axios.send({
        url: "/categories/" + cat.id,
        method: "POST",
        data: formData,
      }).then(() => {
        this.fetchCategories();
      });
    },
    save() {
      this.item.priority = parseInt(this.item.priority);
      this.item.store_id = this.store_id;

      axios.send({
        url: "/categories",
        method: "POST",
        data: this.item
      }).then(() => {
        this.close();
        this.fetchCategories();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
      })
    },
    edit(item) {
      this.item = item;
      this.dialog = true;
    },
    del(item) {
      this.$confirm(
          "Вы точно хотите удалить категорию",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/categories",
          method: "DELETE",
          data: item
        }).then(() => {
          this.fetchCategories();
        })
      })
    }
  }
}
</script>
