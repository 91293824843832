<template>
  <v-card class="mx-2 my-4" elevation="24" max-width="250" flat>
    <v-card class="row justify-space-around no-gutters d-flex align-center" color="rgba(192, 192, 192, 0.6)"
            height="50">
      <v-tooltip top>
        <template v-slot:activator="{ attrs , on }">
          <v-btn v-bind="attrs" v-on="on" color="transparent" fab small @click="upload">
            <v-icon large color="black">mdi-camera-outline</v-icon>
          </v-btn>
        </template>
        Изменить изображение
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-btn fab class="ma-1" v-bind="attrs" v-on="on" small color="rgba(192, 192, 192, 0.8)">
            <v-checkbox light ripple color="black" class="ml-2 pb-5" hide-details v-model="item.hidden"
                        @change="toggle"/>
          </v-btn>
        </template>
        В наличии / Не в наличии
      </v-tooltip>
      <v-dialog v-model="dialog" :max-width="$vuetify.breakpoint.xs ? 374 : 750">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="transparent" fab small @click="dialog = true">
            <v-icon color="black">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <edit-card :row="this.item" @save="save" @closed="dialog = false"/>
      </v-dialog>
      <v-btn fab small color="transparent" @click="delItem">
        <v-icon color="error" large>mdi-delete-outline</v-icon>
      </v-btn>
      <v-tooltip top>
        <template v-slot:activator="{ attrs, on }">
          <v-btn v-bind="attrs" v-on="on" @click="show = !show" color="transparent" fab small>
            <v-icon large color="black">mdi-information-variant</v-icon>
          </v-btn>
        </template>
        Детально о блюде
      </v-tooltip>
    </v-card>
    <v-card v-if="!show" :class="{image:item.hidden}">
      <v-img :src="item.image ? item.image : '/12.jpg'" dark height="200">
        <v-row no-gutters>
          <v-col cols="7">
            <v-tooltip top>
              <template v-slot:activator="{ attrs, on }">
                <v-chip v-bind="attrs" v-on="on" color="rgba(192, 192, 192, 0.8)" class="ma-1" ripple>
                  <v-checkbox light color="black" @change="toggle" v-model="item.discount_disabled" hide-details
                              class="my-0 ml-1 pa-0 d-flex align-center"/>
                  <v-icon v-show="!item.discount_disabled" color="indigo">mdi-sale</v-icon>
                </v-chip>
              </template>
              <span v-if="!item.discount_disabled">В скидках учавствует</span>
              <span v-else>В скидках НЕ учавствует</span>
            </v-tooltip>
          </v-col>
          <v-col cols="5" v-if="!isEditingItemPrice" class="row no-gutters justify-end">
            <v-chip class="chip" color="rgba(192, 192, 192, 0.8)" @click="isEditingItemPrice = true">
              <span class="priceText">{{ item.price }} c.</span>
            </v-chip>
          </v-col>
          <v-col cols="5" v-else>
            <v-text-field rounded v-model="item.price" dense hide-details outlined background-color="grey"
                          color="indigo" autofocus @change="toggle" @blur="isEditingItemPrice = false"/>
          </v-col>
        </v-row>
        <v-footer absolute v-if="item.start_at || item.finish_at " class="justify-end no-gutters" color="transparent">
          <v-chip color="rgba(192, 192, 192, 0.8)">
            <span class="black--text font-weight-bold">{{ item.start_at }} - {{ item.finish_at }}</span>
          </v-chip>
        </v-footer>
      </v-img>
      <v-divider></v-divider>
      <v-card-title>
        <span class="row justify-center font-weight-bold name">{{ item.name }}</span>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters class="justify-center">
          <v-col class="row no-gutters justify-center" cols="12">
            <v-chip v-for="c in item.children" :key="c.id" class="my-1" color="mine" small>
              {{ c.count }} - {{ c.name }}
            </v-chip>
          </v-col>
          <v-col class="row no-gutters justify-center" cols="12">
            <v-chip v-for="c in item.categories" :key="c.id" class="my-1" color="mine" small>
              <span class="d-inline-flex text-truncate">{{ c.name }}</span>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-else :class="{image:item.hidden}">
      <v-row no-gutters class="px-2 mt-2">
        <div class="col-12 row no-gutters justify-space-between py-1">
          <span class="black--text font-weight-bold body-2 d-flex align-center">В скидках участвует:</span>
          <v-icon v-if="!item.discount_disabled" color="black">mdi-check-bold</v-icon>
          <v-icon v-else color="black">mdi-close-thick</v-icon>
        </div>
        <div v-if="!isEditingItemCookTime" class="col-12 row no-gutters justify-space-between py-1">
          <span class="black--text font-weight-bold body-2 d-flex align-center">Время приготов.:</span>
          <span class="black--text font-weight-bold" @click="isEditingItemCookTime = true">{{
              item.cook_time
            }} мин.</span>
        </div>
        <div v-else class="col-12 row no-gutters justify-space-between py-1">
          <span class="col-8 black--text font-weight-bold body-2 d-flex align-center">Время приготов.:</span>
          <v-text-field class="col-4" dense hide-details v-model="item.cook_time" outlined autofocus rounded
                        @change="toggle" @blur="isEditingItemCookTime = false"/>
        </div>
        <div v-if="!isEditingItemMass" class="col-12 row no-gutters justify-space-between py-1">
          <span class="black--text font-weight-bold body-2 d-flex align-center">Масса блюда:</span>
          <span class="black--text font-weight-bold" @click="isEditingItemMass = true">{{ item.mass }} гр.</span>
        </div>
        <div v-else class="col-12 row no-gutters justify-space-between py-1">
          <span class="col-8 black--text font-weight-bold body-2 d-flex align-center">Масса блюда:</span>
          <v-text-field class="col-4" dense hide-details v-model="item.mass" outlined autofocus rounded @change="toggle"
                        @blur="isEditingItemMass = false"/>
        </div>
        <div class="col-12 row no-gutters justify-center caption py-1">
          <span class="black--text font-weight-black text-decoration-underline">Описание:</span>
        </div>
        <div class="col-12 row no-gutters justify-center py-1">
          <span v-if="item.description" class="black--text font-weight-bold font-italic text-center">{{
              item.description
            }}</span>
          <span v-else class="black--text font-italic">Отсутсвует</span>
        </div>
        <div class="col-12 row no-gutters justify-center caption py-1">
          <span class="black--text font-weight-black text-decoration-underline">Уведомление:</span>
        </div>
        <div class="col-12 row no-gutters justify-center py-1">
          <span v-if="item.notification" class="black--text font-weight-bold">{{ item.notification }}</span>
          <span v-else class="black--text font-italic">Отсутсвует</span>
        </div>
      </v-row>
    </v-card>
    <input v-show="false"
           ref="image"
           accept="image/*"
           type="file"
           v-on:change="uploadImage"/>
  </v-card>
</template>


<script>
import axios from "@/services/axios";
import editCard from './edit';

export default {
  components: {editCard},
  data() {
    return {
      item: {},
      dialog: false,
      checkbox: false,
      show: false,
      delDialog: false,
      isEditingItemPrice: false,
      isEditingItemCookTime: false,
      isEditingItemMass: false
    }
  },
  props: {
    row: Object
  },
  mounted() {
    this.item = Object.assign({}, this.row);
  },
  methods: {
    upload() {
      this.$refs["image"].click();
    },
    uploadImage() {
      let file = this.$refs["image"].files[0];

      let formData = new FormData();
      formData.append("image", file);
      axios.send({
        url: "/items/" + this.item.id,
        method: "POST",
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      })
          .then((resp) => {
            this.item = resp.data;
          });
    },
    toggle() {
      this.item.cook_time = parseInt(this.item.cook_time);
      this.item.mass = parseInt(this.item.mass);
      this.item.price = parseFloat(this.item.price);

      axios({
        url: "/items",
        method: "PUT",
        data: this.item
      }).then((resp) => {
        this.item = resp.data;
        this.save(resp.data);
      });
    },
    save(v) {
      this.item = v;
      this.dialog = false;
      this.$emit("save");
    },
    delItem() {
      this.$confirm(
          "Удалить блюдо ?",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/items",
          method: "DELETE",
          data: this.item
        }).then(() => {
          this.delDialog = false;
          this.$emit("delete");
        })
      })

    }
  }
}
</script>
<style scoped>
.image {
  filter: brightness(65%);
}

.priceText {
  font-size: 25px;
  color: black;
  font-weight: bold;
  font-family: "Comic Sans MS";
}

.name {
  text-align: center;
  word-break: keep-all;
}

.chip {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  height: 40px;
  padding-top: 1px;
}


</style>
