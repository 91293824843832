<template>
  <v-card outlined class="cards py-1">
    <v-row no-gutters class="mx-2 pb-1">
      <div class="d-flex align-center">
        <span class="font-italic text-body-2">Создать нового пользователя</span>
      </div>
      <v-spacer></v-spacer>
      <div class="">
        <v-btn @click="dialog = true" x-small fab>
          <v-icon>mdi-plus-thick</v-icon>
        </v-btn>
      </div>
    </v-row>
    <v-divider></v-divider>
    <v-row no-gutters class="mx-2 pt-1" v-for="user in list" :key="user.id">
      <v-col cols="5" class="row no-gutters justify-start d-flex align-center">
        <span class="font-weight-bold">{{ user.login }}</span>
      </v-col>
      <v-col cols="3" class="col col-lg-4 col-md-4 col-sm-4 row no-gutters justify-center d-flex align-center">
        <span class="font-italic caption" v-if="user.is_first_login === true">
          Пароль не изменен
        </span>
        <span v-else class="font-italic caption">
          Пароль изменен
        </span>
      </v-col>
      <v-col cols="4" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-end">
        <v-btn @click="edit(user)" class="mr-4" x-small color="transparent" fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn @click="del(user)" x-small color="transparent" fab>
          <v-icon color="error">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card shaped class="pa-3">
        <v-toolbar elevation="5" class="row no-gutters justify-center" dense color="primary">
          <v-toolbar-title>{{ isNew() ? "Новый пользователь" : "Редактировать пользователя" }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-3">
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.login" :disabled="!isNew()" label="Логин"/>
          </v-col>
          <v-col cols="12">
            <v-text-field rounded outlined dense hide-details v-model="item.password" type="password" :label="isNew() ? 'Пароль' : 'Изменить пароль'"/>
          </v-col>
        </v-row>
       <v-row class="justify-space-around pb-3">
         <v-btn rounded outlined elevation="3" color="error" @click="close">Отменить</v-btn>
         <v-btn rounded outlined elevation="3" color="primary" @click="save">Сохранить</v-btn>
       </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {uuid} from "uuidv4";
import axios from "../../../services/axios";

export default {
  data() {
    return {
      list: [],
      store_id: uuid.Nil,
      dialog: false,
      item: {
        id: uuid.Nil,
        store_id: uuid.Nil,
        login: "",
        password: ""
      },
      defaultItem: {
        id: uuid.Nil,
        store_id: uuid.Nil,
        login: "",
        password: ""
      }
    };
  },

  created() {
    this.store_id = this.$route.params.id;
    this.item.store_id = this.$route.params.id;
    this.fetch();
  },

  methods: {
    fetch() {
      axios({
        url: "/store/user/" + this.store_id,
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      });
    },

    edit(item) {
      this.item = item
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.item = Object.assign({}, this.defaultItem);
      })
    },

    del(item) {
      this.$confirm(
          "Удалить пользователя ?",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/store/user/" + item.id,
          method: "DELETE",
          data: item
        }).then(() => {
          this.fetch();
        });
      });
    },

    isNew() {
      if (!this.item.id) {
        return true
      }
      return !this.item.id === uuid.Nil;
    },

    save() {
      axios.send({
        url: "/store/user",
        method: "POST",
        data: this.item
      }).then(() => {
        this.close();
        this.fetch();
      });
    },
  }
};
</script>
<style scoped>
.cards {
  border-radius: 15px;
}
</style>
