<template>
  <v-card flat class="my-5">
    <v-row class="mx-2 mb-2">
      <v-col cols="10" class="row no-gutters justify-center d-flex align-center">
        <v-select
            dense
            hide-details
            rounded
            outlined
            hide-selected
            clearable
            v-model="item"
            :items="rules"
            item-text="name"
            item-value="id"
            label="Выберите правило"
            persistent-hint
            return-object/>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center d-flex align-center">
        <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded :disabled="!item.name" @click="add" color="primary">
          <span class="font-weight-bold hidden-xs-only">Добавить</span>
          <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-for="item in list" :key="item.id" class="justify-space-around px-4">
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center d-flex align-center">
        <v-text-field v-model="item.name"
                      hide-details
                      dense
                      outlined
                      rounded
                      readonly
                      label="Название"/>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center d-flex align-center">
        <v-text-field v-model="item.min_distance"
                      hide-details
                      dense
                      outlined
                      rounded
                      readonly
                      label="Мин. дистанция"/>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center d-flex align-center">
        <v-text-field v-model="item.max_distance"
                      hide-details
                      dense
                      outlined
                      rounded
                      readonly
                      label="Макс. дистанция"/>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center d-flex align-center">
        <v-text-field v-model="item.min_price"
                      hide-details
                      dense
                      outlined
                      rounded
                      readonly
                      label="Мин. цена заказа"/>
      </v-col>
      <v-col cols="6" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center d-flex align-center">
        <v-text-field v-model="item.delivery_price"
                      hide-details
                      dense
                      outlined
                      rounded
                      readonly
                      label="Цена доставки"/>
      </v-col>
      <v-col cols="8" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-between align-center d-flex">
        <v-checkbox v-model="item.partner"
                    class="ma-0 pa-0"
                    hide-details
                    readonly
                    label="Партнер"/>
        <v-btn icon @click="remove(item)" color="red">
          <v-icon>mdi-minus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import axios from "../../../services/axios";

export default {
  props: {
    store_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      rules: [],
      item: {},
      list: []
    }
  },

  beforeMount() {
    this.fetchRules();
    this.fetch();
  },

  methods: {
    fetch() {
      axios({
        url: "/rules/" + this.store_id,
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      });
    },

    fetchRules() {
      axios.send({
        url: "/rules",
        method: "GET"
      }).then(resp => {
        this.rules = resp.data;
      })
    },

    add() {
      const data = {
        store_id: this.store_id,
        rule_id: this.item.id,
      }
      axios({
        url: "/rules/store",
        method: "POST",
        data: data
      }).then(() => {
        this.fetch();
      });
    },

    remove(item) {
      const data = {
        store_id: this.store_id,
        rule_id: item.id,
      }
      axios({
        url: "/rules/store",
        method: "DELETE",
        data: data
      }).then(() => {
        this.fetch();
      });
    }
  }
}
</script>
